import React from 'react'
import { Routes, Route } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";

import "./App.css";

const clientsBaseUrls = {
  demo: 'https://gua82twtw5.execute-api.ap-south-1.amazonaws.com/demo/api/',
  ugro: 'https://om5lgxkwbj.execute-api.ap-south-1.amazonaws.com/ugro/api/',
}

const LinkProcessing = () => {
  let navigate = useNavigate();
  let params = useParams();
  React.useEffect(() => {
    const baseUrl = clientsBaseUrls[params.client];
    if(params && params.client && params.id && baseUrl) {
      fetch(baseUrl + 'payment/shortlink-ref?shortLink=' + params.id, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
      }).then(res => res.json()).then((data) => {
        if(data && data.referenceLink) {
          window.location.href = baseUrl + "payment/info/" + data.referenceLink
        } else {
          navigate('/')
        }
      }).catch(() => navigate('/'));
      return;
    }
    navigate('/');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return (
    <>
      <div className="loader">Loading...</div>
    </>
  );
};

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<h1>Some error occurred. Please try again after sometime</h1>} />
        <Route path=":client/:id" element={<LinkProcessing />} />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <h1>There's nothing here! - 404</h1>
            </main>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
